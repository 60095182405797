import React, { useEffect, useState } from 'react';
import DashAdmin from '~/Components/DashAdmin';
import FormCourses from '~/Components/Forms/Courses/FormCourses';
import api from '~/Services/api';

// import { Container } from './styles';

export default function EditCourses(props) {
  const { id } = props.match.params;

  const [data, setData] = useState();

  async function requestCourse() {
    const res = await api.get(`course/${id}`);
    const arrCategory = [];
    if (res.data[0].categories_names !== null) {
      res.data[0].categories_names.split(',').map(category => {
        arrCategory.push(category);
      });

      res.data[0].category = arrCategory;
      if (res.data[0].reason_canceled === null) {
        res.data[0].reason_canceled = '';
      }
      setData(res.data[0]);
    } else {
      res.data[0].category = arrCategory;
      setData(res.data[0])
    }
  }
  useEffect(() => {
    requestCourse();
  }, []);
  return (
    <DashAdmin
      content={<FormCourses objData={data} />}
      title={'Editar curso'}
      width={'850px'}
    />
  );
}
