import { makeStyles, createTheme } from '@material-ui/core/styles';

import StylesGlobals from '~/Assets/css/StylesGlobals';
const responsive = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 390,
      md: 960,
      lg: 1315,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: 608,
    width: '100%',
    minHeight: 507,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 8,
    padding: 24,

    '& > p': {
      fontSize: 14,
      lineHeight: '16.8px',
      fontWeight: '400',
      color: '#271e1e',
      marginBottom: 16,
      width: '100%',
      textAlign: 'center',

      '& > span': {
        fontSize: 12,

        [responsive.breakpoints.down('sm')]: {
          fontSize: 10,
        },
      },
    },
  },

  headerModal: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: 46,
    borderBottom: '1px solid #D4D2D2',
    marginBottom: 24,

    '& > img': {
      width: 18,
      height: 18,
      marginTop: 6,
    },

    '& button': {
      width: 30,
      height: 30,
      background: '#fff',
      color: '#d81717',
      outline: 'none',
      textAlign: 'center',
      fontSize: 18,
      cursor: 'pointer',
      transition: 'linear .2s',

      '&:hover': {
        background: '#ee252614',
      },

      [responsive.breakpoints.down('sm')]: {
        width: 12,
      },
    },
  },

  title: {
    display: 'flex',

    '& img': {
      width: '27.41px',
      height: '35.05px',
      marginRight: '16.98px',
    },

    '& h1': {
      fontSize: 24,
      fontWeight: '700',
      color: '#8C0E0E',

      [responsive.breakpoints.down('sm')]: {
        fontSize: 18,
        marginBottom: 16,
      },
    },
  },

  contentModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    height: 400,

    padding: 20,
    [responsive.breakpoints.down('sm')]: {
      padding: 10,
    },


    '& p': {
      fontSize: 18,
      textAlign: 'center',
      fontWeight: '400',
      lineHeight: '21.6px',
      marginBottom: 32,
      color: '#271e1e',

      '& > span': {
        fontWeight: '700',
        lineHeight: '21.6px',
        color: '#271E1E',
      },
    },

    '& > span': {
      fontSize: 14,
      lineHeight: '16.8px',
      fontWeight: '400',
      color: '#271e1e',
      marginBottom: 16,
      width: '100%',
      textAlign: 'center',
    },
  },

  containerRadio: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginBottom: 32,

    '& .MuiIconButton-root': {
      padding: 0,
    },

    '& .MuiFormControlLabel-root': {
      margin: 0,
    },

    '&.MuiFormControlLabel-labelPlacementBottom': {
      marginLeft: 0,
    },

    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#b01010',
    },
  },

  radio: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 15,
    height: 37,
  },

  textArea: {
    minHeight: 76,
    width: '100%',
    borderRadius: 8,
    outline: 'none',
    padding: '8.98px 8px',
  },

  button: {
    padding: '12px 23px',
    maxWidth: 288,
    width: '100%',
    minHeight: 38,
    borderRadius: 8,
    background: '#d81717',
    textAlign: 'center',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    letterSpacing: 2,

    '&:disabled': {
      background: '#c1c1c1',
      border: 'none',
    },
  },

  containerModalSubscribeCourse: {
    maxWidth: 608,
    width: '100%',
    maxheigth: 490,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 24,
    borderRadius: 8,

    '& button': {
      maxWidth: 288,
      width: '100%',
      height: 38,
      borderRadius: 8,
      background: '#419a58',
      color: '#fff',
      padding: '12px 25px',

      '&:hover': {
        background: '#419a58',
        opacity: 0.9,
      },

      '& span': {
        textTransform: 'initial',
      },

      '& img': {
        width: 40,
        height: 40,
        marginRight: 10,
      },
    },
  },

  containerModalSlider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& > a': {
      background: '#419a58',
      color: '#fff',
      maxWidth: 288,
      width: '100%',
      borderRadius: 5,
      padding: '12px 25px',
      height: 38,
      textTransform: 'initial',

      '&:hover': {
        background: '#419a58',
        opacity: 0.9,
      },
    },

    '& button:disabled': {
      background: '#00000042',
    },
  },

  headerSubscribeModal: {
    display: 'flex',
    width: '100%',
    minHeight: 46,
    borderBottom: '1px solid #D4D2D2',
    marginBottom: 24,

    '& .MuiButton-root': {
      minWidth: 15,
    },

    '& img': {
      width: 27,
      height: '19.5px',
      marginRight: '17.5px',
      marginTop: 5,

      [responsive.breakpoints.down('sm')]: {
        width: 18,
        height: 13,
      },
    },

    '& p': {
      fontSize: 24,
      fontWeight: '700',
      color: '#d81717',
      width: '100%',

      [responsive.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },

    '& button': {
      width: 30,
      height: 30,
      background: '#fff',
      color: '#d81717',
      outline: 'none',
      textAlign: 'center',
      fontSize: 18,
      cursor: 'pointer',
      transition: 'linear .2s',

      '&:hover': {
        background: '#ee252614',
      },

      [responsive.breakpoints.down('sm')]: {
        width: 12,
      },
    },
  },

  contentModalList: {
    width: '100%',
    height: 440,
    marginBottom: 20,
    borderBottom: '1px solid #D4D2D2',
    overflow: 'auto',

    [responsive.breakpoints.down('sm')]: {
      overflowX: 'hidden',
    },

    '& ul li': {
      listStyle: 'inside',
      lineHeight: '22.4px',
      color: '#000',
      fontSize: 18,
      fontWeight: '400',
      marginBottom: 18,

      [responsive.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
  },

  contentModalCongratulations: {
    textAlign: 'center',
    borderBottom: '1px solid #D4D2D2',
    marginBottom: 24,

    '& p': {
      fontSize: 18,
      lineHeight: '21.6px',
      fontWeight: '400',
      marginBottom: 24,
      color: '#5D5656',

      '& b': {
        color: '#271e1e',
      },
    },
  },

  contentModalContactTeacher: {
    textAlign: 'center',
    marginBottom: 24,

    '& p': {
      fontSize: 18,
      lineHeight: '21.6px',
      fontWeight: '400',
      marginBottom: 24,
      color: '#5D5656',

      '& b': {
        color: '#271e1e',
      },
    },
  },

  ContactTeacher: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiSvgIcon-root': {
      fill: '#419a58',
      width: '1.8rem',
      height: '2rem',
      marginRight: 10,
      cursor: 'pointer',
    },

    '& span': {
      fontSize: 16,
      fontWeight: '400',
      lineHeight: '28.8px',
      padding: '0 10',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      margin: '0 5px',

      '&:nth-child(3)': {
        color: '#d01717',
      },
    },
  },

  canceledCourse: {
    height: 420,
    maxWidth: 310,
    width: '100%',
    background: '#d01717',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',

    [responsive.breakpoints.down('sm')]: {
      height: 440,
    },
  },

  closeButtonModalUnsubscribe: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    background: '#fff',
    borderRadius: '50%',
    margin: '20px 20px 0 0 ',

    '& .MuiSvgIcon-root': {
      fontSize: '1.8rem',
      color: '#000000',
      margin: 10,
    },
  },

  boxCanceled: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '80%',
    width: '100%',
    margin: '0 auto',
    height: 330,

    [responsive.breakpoints.down('sm')]: {
      height: 350,
    },

    '& h3': {
      marginTop: 20,
      fontSize: 22,
      color: '#fff',
    },

    '& p': {
      textAlign: 'center',
      fontSize: 18,
      marginTop: 10,
      color: '#fff',
    },

    '& .MuiTextField-root': {
      width: '100%',

      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
    },

    '& textarea': {
      marginTop: 20,
      width: '100%',
      borderRadius: 5,
      background: '#ff7474',
      padding: 10,
      color: '#271e1e',
      outline: 'none',
      border: 'none',

      '&::placeholder': {
        color: '#271e1e',
      },
    },

    '& button': {
      marginTop: 25,
      fontSize: 22,
      color: '#fff',
      cursor: 'pointer',
      textTransform: 'initial',

      '&:disabled': {
        color: '#f96969',
      },

      [responsive.breakpoints.down('sm')]: {
        marginTop: 40,
      },
    },
  },

  rating: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    '& > span': {
      marginBottom: 20,
    },

    '& .motivo': {
      marginBottom: 5,
      fontSize: 15,
      '& > span': {
        fontSize: 12,
      },
    },
  },
}));

export default useStyles;
