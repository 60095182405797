import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiSelect-select:focus': {
      backgroundColor: '#fff'
    }
  },
  inputFull: {
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0'
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  bgLabel: {
    background: '#fafafa'
  },
  inputBg: {
    background: '#ffffff'
  },

}));

export default useStyles
